.MenuFirstElement {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

.MenuFirstElement:hover {
    cursor: pointer;
}

.MenuFirstElement__active {
    background: linear-gradient(90deg,rgba(255,83,83,.2) 6.25%,rgba(255,83,83,0) 27.5%),#f4f6f8;
}

.MenuFirstElement__active::before {
    content: "";
    width: 4px;
    height: 64px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0px;
    background-color: #ff5353;
}

.MenuFirstElement__helper {
    position: absolute;
    left: 52px;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(30,34,72,.16);
    font-size: 16px;
    font-weight: 400;
    padding: 2px 8px;
    transition: .25s ease;
    transform: translateY(-50%) scale(.9);
    white-space: nowrap;
    z-index: 10;
}

.MenuFirstElement__helper-active {
    opacity: 1;
    visibility: visible;
}

.MenuFirstElement__active:hover {
    cursor: pointer;
}

.MenuFirstElement__container {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px solid transparent;
}

.MenuFirstElement__container:hover {
    border: 2px solid #2D336C;
    transition: .25s ease;
}

.MenuFirstElement__container-active:hover {
    border: 2px solid transparent;
}

.MenuFirstElement__icon {
    width: 28px;
    height: 28px;
}

.MenuFirstElement__icon-active {
    fill: #2D336C;
}

.MenuFirstElement__icon-inactive {
    fill: #fff;
}

.MenuSecond__hide {
    visibility: hidden !important;
}