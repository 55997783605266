.preloader-block {
    width: 100%;
    min-height: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .preloader-block__fix {
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center !important;
    position: fixed;
    z-index: 10;
    background-color: rgb(0, 0, 0, 0.1);
  }
  
  .loader {
    width: 48px !important;
    height: 48px;
    border: 5px solid #919191;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
      @keyframes rotation {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
    } 