.MenuFirstProfile {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MenuFirstProfile:hover {
    cursor: pointer;
}

.MenuFirstProfile__profile {
    width: 36px;
    height: 36px;
    margin: 0 auto;
    border-radius: 50%;
    background-color: gray;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
}

.MenuFirstProfile__status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    margin: 20px 0 0 30px;
}

.status__green {
    background-color: #22c993;
}

.status__red {
    background-color: #ff5353;
}

.status__orange {
    background-color: #ef8e06;
}

.status__gray {
    background-color: #ffe1ba;
}