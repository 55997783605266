.Order {
    background: #f4f6f8;
    width: auto !important;
    min-height: 100%;
    display: flex;
    padding: 40px;
    flex-wrap: wrap;
    align-content: flex-start;
}

.order-top {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column !important;
}

.order-links {
    color: #636f7f;
	font-size: 12px;
    margin-bottom: 5px;
    width: auto !important;
    justify-content: flex-start !important;
}

.order-links a {
    color: #636f7f;
	font-size: 12px;
    text-decoration: none;
}

.order-links a:hover {
    color: #0052cc;
    text-decoration: underline;
    cursor: pointer;
}

.order-links span {
    padding: 0 5px;
}

.order-h1 {
    font-size: 24px;
	font-weight: 500;
    margin-bottom: 40px;
    display: flex;
    align-items: flex-end;
}

.order-h1-btn {
    font-size: 24px;
	font-weight: 500;
    height: 60px;
    margin-bottom: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.ContentBlock {
    width: 100% !important;
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    flex-wrap: wrap;
    background-color: #fff;
    margin-bottom: 25px;
}

.ContentBlockBody {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.content-block-top {
    display: flex;
    width: 100%;
	height: 64px;
    border-bottom: 1px solid #dee2e6;
    align-items: center;
    justify-content: space-between;
}

.content-block-top:hover {
    cursor: pointer;
}

.content-block-title {
    width: auto;
	font-size: 16px;
	font-weight: 500;
    margin-left: 25px;
}

.content-block-close {
    width: 24px;
    height: 24px;
    margin-right: 25px;
    background: url('./img/arrow.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(180deg);
}

.content-block-close:hover {
    cursor: pointer;
}

.content-block-close__active {
    transform: rotate(90deg);
}

.content-block-content {
    width: 100%;
    height: calc(100% - 64px - 1px);
    transition: height 0.5s ease;
}

.content-block-content__hide {
    height: 0px;
    display: none;
}

.order-element {
    width: 100%;
    display: flex ;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 25px;
}

.order-element select {
    border: 1px solid #dee2e6;
	border-radius: 6px;
	color: #1e2248;
	font-size: 13px;
    width: 222px;
	height: 31px;
	line-height: 26px;
	transition: .2s ease;
    margin-right: 5px;
    padding: 0 10px;
}

.order-element label {
    font-size: 13px;
    color: #636f7f;
    width: 150px;
}

.order-element textarea {
    width: 100% !important;
    height: 100px !important;
    vertical-align: top;
    text-align: start;
    border: 1px solid #dee2e6;
	border-radius: 6px;
	color: #1e2248;
	font-size: 13px;
    width: 200px;
	height: 27px;
	line-height: 26px;
	padding: 1px 10px;
	transition: .2s ease;
    resize: none;
}

.order-element input {
    border: 1px solid #dee2e6;
	border-radius: 6px;
	color: #1e2248;
	font-size: 13px;
    width: 200px;
	height: 27px;
	line-height: 26px;
	padding: 1px 10px;
	transition: .2s ease;
    margin-right: 5px;
}

.order-element__time-range {
    border: 1px solid #dee2e6;
	border-radius: 6px;
	color: #1e2248;
	font-size: 13px;
    width: 85px !important;
	height: 27px;
	line-height: 26px;
	padding: 1px 10px;
	transition: .2s ease;
    margin-right: 8px !important;
}

.order-element input:disabled {
    background-color: #e5fcae;
    border: #d1e79b !important;
}

.order-element input:focus {
    border: 1px solid #005eeb;
    outline: 3px solid #d1e5f9;
}

.order-element input::placeholder {
    color: #b4b7b8;
    font-size: 13px;
}

.order-element-type-2 {
    width: 100%;
    display: flex ;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 25px;
}

.order-element-type-2 select {
    border: 1px solid #dee2e6;
	border-radius: 6px;
	color: #1e2248;
	font-size: 13px;
    width: 222px;
	height: 31px;
	line-height: 26px;
	transition: .2s ease;
    margin-right: 5px;
    padding: 0 10px;
}

.order-element-type-2 label {
    font-size: 13px;
    color: #636f7f;
    width: 150px;
    margin-bottom: 10px;
}

.order-element-type-2 input {
    border: 1px solid #dee2e6;
	border-radius: 6px;
	color: #1e2248;
	font-size: 13px;
    width: 200px;
	height: 27px;
	line-height: 26px;
	padding: 1px 10px;
	transition: .2s ease;
    margin-right: 5px;
}

.order-element-type-2 input:focus {
    border: 1px solid #005eeb;
    outline: 3px solid #d1e5f9;
}

.order-element-type-2 input::placeholder {
    color: #b4b7b8;
    font-size: 13px;
}

.order-element-checkbox {
    width: 15px !important;
    height: 15px !important;
    margin-right: 10px !important;
}

.card-shop {
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    background-color: rgb(0, 0, 0, 0.2);
    padding: 40px;
    left: 0;
    top: 0;
    z-index: 20;
}

.card-shop__active {
    display: flex;
}

.card-shop-container {
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    border: 1px solid #dee2e6;
    border-radius: 5px;
    background-color: #fff;
    z-index: 21;
    display: flex;
    flex-wrap: wrap;
}

.card-shop-left {
    display: flex;
    width: 250px;
    height: calc(100% - 40px);
    border-right: 1px solid #dee2e6;
    background-color: #f9fafb;
    padding: 20px;
    position: relative;
    flex-wrap: wrap;
    align-content: space-between;
}

.order-element-checkbox-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-items: flex-start;
}

.card-shop-right {
    width: calc(100% - 291px);
    height: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
}

.card-shop-right-top {
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.card-shop-right-top-h1 {
    font-size: 18px;
    margin-left: 20px;
    flex-shrink: 1;
}

.card-shop-right-top-close {
    width: 55px;
    height: 100%;
    border-left: 1px solid #dee2e6;
    background: url('./img/close.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    flex-shrink: 0;
}

.card-shop-right-top-close:hover {
    cursor: pointer;
}

.card-shop-right-content {
    width: 100%;
    height: calc(100% - 55px);
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    align-content: flex-start;
}

.card-shop-right-element {
    width: 100%;
    height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 12px;
}

.card-shop-right-element:hover {
    cursor: pointer;
    background-color: #f0f1f5;
}

.card-shop-right-element-td {
    display: flex;
    align-items: center;
    padding: 0 15px;
    width: 20%;
    flex-grow: 1;
    height: 100%;
}

.card-shop-right-element-td_choosen{
    color: #636f7f;
}

.card-shop-right-element-td input {
    width: 50px;
    height: 25px;
    border: 1px solid #dee2e6;
	border-radius: 5px;
	color: #1e2248;
	font-size: 12px;
	padding: 1px 10px;
    text-align: center;
}

.order-element-search {
    width: auto;
    display: flex ;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px;
}

.order-element-search input {
    border: 1px solid #dee2e6;
	border-radius: 6px 0 0 6px;
	color: #1e2248;
	font-size: 13px;
    width: 200px;
	height: 27px;
	line-height: 26px;
	padding: 1px 10px;
	transition: .2s ease;
    margin-top: 27px
}

.order-element-search input:focus {
    border: 1px solid #dee2e6;
    outline: none
}

.order-element-search input:hover {
    border: 1px solid #005eeb;
    outline: 3px solid #d1e5f9;
}

.order-element-search input::placeholder {
    color: #b4b7b8;
    font-size: 13px;
}

.order-element-search div {
    border: 1px solid #dee2e6;
	border-radius: 0px 6px 6px 0px;
    width: 27px;
    height: 29px;
    margin-top: 27px;
    margin-left: -1px;
    background-image: url('./img/search.svg'), linear-gradient(180deg,#fff,#e9ecee);
    background-position: bottom right;
    background-size: contain;
    background-repeat: no-repeat;
}

.order-element-search div:hover {
    cursor: pointer;
}

.order-customer-box-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.order-customer-box {
    background: #f6f6f6;
	border-radius: 4px;
	display: inline-block;
	font-size: 14px;
    color: #0052cc;
	line-height: 1.42857143;
	margin-right: 5px;
	padding: 5px 14px;
	vertical-align: middle;
    margin-left: 150px;
}

.order-customer-box:hover {
    text-decoration: underline;
    cursor: pointer;
}

.order-customer-box::after {
    content: "";
    background: url('./img/link.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-flex;
    width: 13px;
    height: 13px;
    margin-left: 5px;
	padding-bottom: 3px;
}

.order-customer-box-close {
    width: 30px;
    height: 30px;
	border: 1px solid #d8d8d8;
	border-radius: 4px;
    background: url('./img/close-2.svg');
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}

.order-customer-box-close:hover {
    cursor: pointer;
}

.customer-list {
    width: 372px !important;
    max-height: 145px;
    height: auto;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute !important;
    z-index: 20;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 5px 0;
    border: 1px solid #dee2e6;
    border-radius: 5px;
}

.customer-list div {
    width: 100%;
    height: 29px;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.customer-list div div {
    width: auto;
}

.customer-list div:hover {
    cursor: pointer;
    background-color: #f4f6f8;
}

.customer-list-btn {
    display: flex !important;
    justify-content: center !important;
    font-weight: 600;
}

.order-h1-container {
    display: flex;
    width: auto;
    flex-direction: column;
    margin-left: 25px;
}
.order-h1-head {
    color: #636f7f;
	font-style: normal;
    font-size: 12px;
}
.order-h1-body {
    font-weight: 600;
    font-size: 14px;
}
.order-h1-body__nobold {
    font-weight: 400 !important;
}

.order-task-bar {
    width: 100%;
    height: 68px;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
}

.order-task-bar__top {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    justify-content: space-between;
}
.order-task-bar__top-left {
    display: flex;
    height: 100%;
    align-items: center;
}
.order-task-bar-status__top {
    height: 8px;
    display: flex;
    padding: 0 7px;
}
.order-task-bar-status__bottom {
    height: 32px;
    background-color: #d1e79b;
    font-weight: 600;
    font-size: 13px;
    padding-left: 12px;
    display: flex;
    align-items: center;
    border-radius: 3px 0 0 0;
}
.order-task-bar-status__bottom:hover {
    cursor: pointer;
}
.order-task-bar-status__bottom div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}
.order-task-bar__bottom {
    width: 100%;
    height: 28px;
    background-color: #eef1f3;
    padding-left: 5px;
    display: flex;
}
.order-task-bar__bottom ul {
    display: flex;
    width: auto;
    height: 100%;
    align-items: center;
}
.order-task-bar__bottom ul li {
    display: flex;
    margin: 0 10px;
    color: #8a96a6;
	font-size: 11px;
	font-weight: 500;
    text-transform: uppercase;
}
.order-task-bar__bottom ul li:hover {
    cursor: pointer;
}
.order-task-bar__bottom ul li::marker {
    content: "";
}

.task-bar__order-icon {
    width: 35px;
    height: 8px;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
}
.task-bar__order-icon:hover {
    cursor: pointer;
}

.order-icon__big {
    height: 10px !important;
}

.order-icon__vip {
    background: url(./elements/img/vip.svg);
}
.order-icon__vip:hover {
    background: url(./elements/img/vip_active.svg);
}
.order-icon__vip-active {
    background: url(./elements/img/vip_active.svg);
}

.order-icon__bad {
    background: url(./elements/img/bad.svg);
}
.order-icon__bad:hover {
    background: url(./elements/img/bad_active.svg);
}
.order-icon__bad-active {
    background: url(./elements/img/bad_active.svg);
}

.order-icon__pass {
    background: url(./elements/img/pass.svg);
}
.order-icon__pass:hover {
    background: url(./elements/img/pass_active.svg);
}
.order-icon__pass-active {
    background: url(./elements/img/pass_active.svg);
}

.order-icon__call {
    background: url(./elements/img/call.svg);
}
.order-icon__call:hover {
    background: url(./elements/img/call_active.svg);
}
.order-icon__call-active {
    background: url(./elements/img/call_active.svg);
}

.btn-container-left {
    margin-left: 25px;
}
.btn-container-link {
    display: flex;
    align-items: center;
    color: #005eeb;
    font-size: 12px;
    padding: 8px 15px;
}
.btn-container-link:hover{
    cursor: pointer;
    background-color: #f7faff;
}
.btn-container {
    display: flex;
    align-items: center;
    color: #005eeb;
    font-size: 12px;
}
.btn-container:hover {
    cursor: pointer;
    text-decoration: underline;
}
.btn-container::before {
    content: "";
    display: flex;
    margin-right: 5px;
    width: 15px;
    height: 15px;
    background: url('./img/burger.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.popover-content-link {
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 1px;
    box-shadow: 0 10px 15px 8px rgba(46, 41, 51, 0.08),
        0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    min-width: 7rem;
    z-index: 9;
}
.popover-content-status {
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 1px;
    box-shadow: 0 10px 15px 8px rgba(46, 41, 51, 0.08),
        0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    min-width: 206px;
    z-index: 9;
    margin-top: 8px;
    margin-left: -13px;
}
.popover-content-status-group {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    margin: 5px 5px 5px 15px;
    padding: 5px;
    border-radius: 4px;
    flex-direction: column
}
.popover-content-status-item {
    display: flex;
    padding: 5px 10px;
    width: 89%;
}
.popover-content-status-item:hover {
    cursor: pointer;
    background-color: rgb(0, 0, 0, 0.1);
}
.popover-content {
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 1px;
    box-shadow: 0 10px 15px 8px rgba(46, 41, 51, 0.08),
        0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    min-width: 7rem;
    padding: 1rem;
    z-index: 9;
}
.popover-content.bg-white {
    background-color: #fff;
}
.popover-content-right {
    margin-left: 9px;
}
.no-library .popover-content {
    position: absolute;
    margin-top: 0.5rem;
}

.order-task-bar__separator {
    width: 1px;
    height: 100%;
    background-color: #e9ecee;
    margin: 0 25px;
}

.order-task-bar__status {
    width: 206px;
    height: 100%;
}

.status-group-item {
    display: flex;
    flex-grow: 1;
    border: 1px solid #fff;
    border-radius: 4px;
}
.status-group-item__hide {
    border: 0 !important;
    border-radius: 0px !important;
}

.delivery-input {
    max-width: 37px !important;
	max-height: 20px !important;
	text-align: center;
}

.order-reservation-block {
    height: 48px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-right: 50px;
}

.order-reservation-block__button {
    display: flex;
	align-items: center;
	padding: 5px 22px 7px 21px;
	background-color: #fff;
	background-image: linear-gradient(180deg, #fff, #e9ecee);
	border: 1px solid #dee2e6;
	font-size: 14px;
	font-weight: 500;
	border-radius: 5px;
	margin-right: 25px;
    height: 18px;
}
.order-reservation-block__button:hover {
    cursor: pointer;
    background-image: linear-gradient(180deg, #e9ecee, #fff);
}

.order-reservation-block-button__active {
    background-image: linear-gradient(180deg, #b7b7b7, #b7b7b7);
    color: #636f7f;
}
.order-reservation-block-button__active:hover {
    background-image: linear-gradient(180deg, #b7b7b7, #b7b7b7) !important;
    color: #636f7f !important;
}

.shop-cart__paginator {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: -51px;
}

.container-range {
    max-width: 100px;
    display: flex !important;
    flex-wrap: nowrap !important;
}
.input-range {
    width: 35px !important;
}
