.address-popup-container {
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background-color: #fff;
    width: 350px !important;
    padding: 10px 0;
    margin-top: 5px;
    max-height: 250px;
    position: relative;
    overflow-y: scroll;
}

.address-popup-element {
    height: auto;
    min-height: 25px;
    width: 100%;
    padding: 5px 15px;
    display: flex;
    align-items: center;
}

.address-popup-element:hover {
    background-color: #d1e5f9;
    cursor: pointer;
}

.address-map {
    width: 100% !important;
    height: 615px;
    border: 1px solid #dee2e6;
    padding: 3px;
    transition: all 0.5s ease;
}

.address-map__disabled {
    width: 0 !important;
}

.address-map div {
    width: 100% !important;
}

.address-metro-line {
    width: 8px !important;
    height: 8px;
    border-radius: 50%;
    margin-right: 10px;
}

#address-link-handle {
    width: auto !important;
    color: #005eeb;
	font-size: 13px;
    margin-top: 8px;
}

#address-link-handle:hover {
    cursor: pointer;
    text-decoration: underline;
}