.User {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.user_top {
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
}

#user-save__success {
    width: auto;
    padding: 9px 20px;
    border-radius: 7px;
    background-color: #d6f8a4;
    margin-left: 25px;
    display: none;
    transition: all 0.5s ease;
}

#user-save__wrong {
    width: auto;
    padding: 9px 20px;
    border-radius: 7px;
    background-color: #f8b9a4;
    margin-left: 25px;
    display: none;
    transition: all 0.5s ease;
}

.user-foother {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.user-save__active {
    display: flex !important;
}