.MenuFirst {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 64px;
    height: 100%;
    min-height: 100%;
    background-color: #1e2248;
    flex-grow: 0;
}

.MenuFirst__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MenuFirst__container-top {
    justify-content: top;
}

.MenuFirst__container-bottom {
    justify-content: end;
}

.MenuFirst__icon {
    width: 40px;
    height: 40px;
    margin: 12px 0 12px 0;
    background: url('./img/MenuFirst__icon.svg') no-repeat;
}

.MenuFirst__icon:hover {
    cursor: pointer;
}

@media (min-height: 700px) {
    #menu_element-more {
        display: none;
    }
}

@media (max-height: 699px) {
    #menu_element-notifications {
        display: none;
    }

    #menu_element-requests {
        display: none;
    }
}

@media (max-height: 649px) {
    #menu_element-chinaOrders {
        display: none;
    }

}

@media (max-height: 579px) {
    #menu_element-mail {
        display: none;
    }

}

@media (max-height: 519px) {
    #menu_element-chat {
        display: none;
    }

}

@media (max-height: 459px) {
    #menu_element-tasks {
        display: none;
    }

}

@media (max-height: 399px) {
    #menu_element-analytics {
        display: none;
    }

}

@media (max-height: 329px) {
    #menu_element-settings {
        display: none;
    }

}