.marking_head {
    width: calc(100% - 80px);
    display: flex;
    font-size: 24px;
	font-weight: 500;
	letter-spacing: -.3px;
	line-height: 32px;
	padding: 40px;
    flex-wrap: wrap;
    background: #fff;
}

.marking_head div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.marking-head_link {
    text-decoration: none;
    font-size: 12px;
    color: #005eeb;
}

.marking-head_link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.marking_contant {
    width: calc(100% - 80px);
    /* display: flex; */
    padding: 0 40px 0 40px;
    background-color: #f4f6f8;
    margin-bottom: 40px;
}

.marking_contant__user {
    width: 100%;
    display: flex;
    padding: 0 40px 0 40px;
    background-color: #f4f6f8;
    margin-bottom: 40px;
    position: relative;
}

.marking_contant__container {
    min-width: calc(100% - 80px);
    display: flex;
    padding: 0 40px 0 40px;
    background-color: #f4f6f8;
    margin-bottom: 40px;
    position: relative;
}

.marking_contant__common {
    width: max-content;
    min-width: 100%;
    background-color: #fff;
    border: 1px solid #e9ecee;
    border-radius: 4px;
    margin-top: -25px;
    margin-bottom: 100px;
    display: flex;
    position: relative;
}

.marking-contant__body {
    width: 100%;
    background-color: #fff;
    border: 1px solid #e9ecee;
    border-radius: 4px;
    margin-top: -25px;
    margin-bottom: 100px;
    display: flex;
    position: relative;
}

.marking-contant__body-element {
    width: 100%;
    background-color: #fff;
    border: 1px solid #e9ecee;
    border-radius: 4px;
    margin-top: -25px;
    margin-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
}

.marking-contant__body-status {
    border-radius: 4px;
    margin-top: -25px;
    margin-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
}

.marking-contant__body div {
    width: auto
}

.container__row {
    width: 100% !important;
    display: flex;
}

.container__row div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.container__column {
    width: 42% !important;
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.container__column div {
    width: 100%;
    position: relative;
}

.foother-container {
    width: 100%;
    height: 55px;
    background-color: #fff;
	border-top: 1px solid #dee2e6;
	-webkit-transition: background-color .4s ease-out;
	transition: background-color .4s ease-out;
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 10;
    align-items: center;
    padding-left: 65px;
}

.foother-container-m0 {
    width: 100%;
    height: 55px;
    background-color: #fff;
	border-top: 1px solid #dee2e6;
	-webkit-transition: background-color .4s ease-out;
	transition: background-color .4s ease-out;
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 10;
    align-items: center;
    padding-left: 65px;
    margin-left: -40px !important;
}

.foother-container-in {
    min-width: calc(100% + 40px);
    margin-left: -20px;
    margin-bottom: -20px;
    height: 55px;
    background-color: #fff;
	border-top: 1px solid #dee2e6;
    display: flex;
    bottom: 0;
    z-index: 10;
    align-items: center;
    justify-content: center;
}

.head-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.head-first-line {
    margin-bottom: 15px;
    display: flex;
}

.head-first-line__button-group {
    width: fit-content !important;
}

.head-first-line div {
    display: flex;
    justify-content: flex-start;
}

.head-first-line div span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.head-second-line {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}