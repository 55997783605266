.invoice {
    width: 978px;
    margin: 0 auto;
    font-size: 17px;
}

#root {
    display: flex;
    flex-direction: column;
}

.btn-width {
    width: 45px !important;
  }