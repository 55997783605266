.btn-close {
    width: 32px;
    height: 32px;
}

.btn-close:hover {
    cursor: pointer;
}

.btn-close svg path {
    fill: #afb9c3;
}

.btn-close svg path:hover {
    fill: #636f7f;
}
