/* .Table {
    width: auto;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
} */

.Table {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    align-content: flex-start;
    overflow-y: hidden;
}

/* .Table table {
    width: 100%;
    display: table;
	border-collapse: collapse;
	box-sizing: border-box;
	text-indent: initial;
	border-spacing: 2px;
    -webkit-text-size-adjust: 100%;
	color: #1e2248;
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Roboto,sans-serif;
	font-size: 12px;
	line-height: 1.2;
    overflow-x: auto;
} */

.Table table {
    border-collapse: collapse;
	color: #1e2248;
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Roboto,sans-serif;
	font-size: 12px;
}

.Table thead {
    width: auto;
    min-width: 100% !important;
    height: 41px;
    display: table-header-group;
	vertical-align: middle;
    position: relative;
}

.Table thead tr {
    width: auto;
    min-width: 100% !important;
    height: 40px;
    display: flex !important;
    border-bottom: 1px solid #e9ecee;
    border-radius: 5px 5px 0 0;
    background-color: #f4f6f8;
    position: absolute;
}

.Table thead tr th {
    color: #636f7f;
	font-size: 11px;
	font-weight: 400;
    padding-left: 12px;
    text-align: left;
    height: 100%;
    border-bottom: 1px solid #e9ecee;
    align-items: center;
	display: flex;

}

.Table thead tr th span {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 15px;
}

.Table thead tr th span::before {
    content: "";
    display: flex;
    border: 4px solid transparent;
    border-bottom: 4px solid gray;
    margin-right: 5px;
    margin-top: -11px;
    position: absolute;
    left: 0;
}

.Table thead tr th span::after {
    content: "";
    display: flex;
    border: 4px solid transparent;
    border-top: 4px solid gray;
    margin-right: 5px;
    margin-top: 10px;
    position: absolute;
    left: 0;
}

.Table thead tr th span:hover {
    cursor: pointer;
}

.Table tbody {
    width: auto;
    min-width: 100% !important;
    display: table-header-group;
	vertical-align: middle;
    position: relative;
}

.Table tbody tr {
    width: auto;
    min-width: 100% !important;
    height: 50px !important;
    display: flex !important;
    border-radius: 5px 5px 0 0;
    align-items: center;
}

.Table tbody tr:hover {
    cursor: pointer;
    /* background-color: #f4f6f8; */
}

.Table tbody tr td {
	font-size: 11px;
	font-weight: 400;
    padding-left: 12px;
    text-align: left;
    height: 100%;
    border-bottom: 1px solid #e9ecee;
    align-items: center;
	display: flex;
}

.table-users_space {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.table-users_space a {
    color: #0052cc;
    text-decoration: none;
}

.table-users_space a:hover {
    cursor: pointer;
    text-decoration: underline;
}

.table-users_left-span {
    width: auto;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.table-users_right-span {
    margin-left: 10px;
}

.table-users_img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #636f7f;
}

.table-users_status-1 {
    color: #22c993;
    margin-left: 10px;
}

.table-users_status-2 {
    color: #ff5353;
    margin-left: 10px;
}

.table-users_status-3 {
    color: #ef8e06;
    margin-left: 10px;
}

.table-users_status-4 {
    color: #ffe1ba;
    margin-left: 10px;
}

.Table tbody tr td ul li {
    font-size: 10px;
    text-transform: lowercase;
}

.Table tbody tr td ul li::marker {
    content: "";
}

.table-empty-data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-empty-data td {
    width: 100%;
}

.table-col {
    width: 30% !important;
    min-width: 200px;
}

.table-col span {
    padding: 5px 15px;
    border-radius: 4px;
}

.table-col-4 {
    width: 25% !important;
    min-width: 200px;
}

.table-col-4 span {
    padding: 5px 15px;
    border-radius: 4px;
}

.table-status-col {
    width: 24% !important;
    min-width: 150px;
}

.table-delivery-col {
    width: 15% !important;
    min-width: 120px;
}

.table-users-col-1 {
    width: 30% !important;
    min-width: 163px;
}
.table-users-col-2 {
    width: 20% !important;
    min-width: 100px;
}
.table-users-col-3 {
    width: 10% !important;
    min-width: 100px;
}
.table-users-col-4 {
    width: 10% !important;
    min-width: 100px;
}
.table-users-col-5 {
    width: 10% !important;
    min-width: 100px;
}
.table-users-col-6 {
    width: 10% !important;
    min-width: 100px;
}
.table-users-col-7 {
    width: 10% !important;
    min-width: 100px;
}

.table-group-col {
    width: 50%;
    min-width: 100px;
}

.table-orders-col1 {
    width: 50px !important;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.table-orders-col2 {
    width: 100px !important;
    min-width: 100px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.table-orders-col {
    width: 10% !important;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.table-orders-col__center {
    justify-content: center !important;
}

.reservation-btn {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #0052cc;
    color: #fff;
}
.reservation-btn:hover {
    background-color: #0f5cd0;
}

.table-status {
    width: 20px !important;
    height: 100%;
    display: flex;
    background-color: #f4f6f8;
    color: #636f7f;
    border-right: 1px solid #e9ecee;
	font-size: 10px;
	text-transform: uppercase;
    position: relative;
    transition: width 0.5s ease;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
}

.table-status span:hover {
    color: #a8aeb5;
    cursor: pointer;
}

.table-status div:hover {
    color: #a8aeb5;
    cursor: pointer;
}

.table-status__active {
    width: 140px !important;
}

.table-status span {
	margin-left: 1px;
	margin-top: 43px;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
    position: absolute;
}

.table-status span::after {
    content: "";
    width: 23px;
    height: 23px;
    position: absolute;
    background-image: url('./img/burger-menu.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(-90deg);
    margin-left: 7px;
	margin-top: -11px;
}

.table-status-top {
    width: 100% !important;
	height: 40px;
    border-bottom: 1px solid #e9ecee;
    display: flex;
    align-items: center;
    justify-content: center;
}

#table-status-contain {
    padding: 10px;
}
#table-status-contain li {
    margin-bottom: 5px;
}
#table-status-contain li::marker {
    content: "";
}

.table-box {
    width: 100% !important;
    max-width: 100%;
    display: flex;
}

.table-box__column {
    width: 100% !important;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.table-goods-top {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-goods-top-btn {
    display: flex;
    align-items: center;
    padding: 5px 10px 7px 15px;
    background-color: #fff;
	background-image: linear-gradient(180deg,#fff,#e9ecee);
	border: 1px solid #dee2e6;
    font-size: 14px;
	font-weight: 500;
    border-radius: 5px;
    margin-right: 25px;
}

.table-goods-top-btn::after {
    content: "+";
    margin-left: 5px;
    font-size: 16px;
    color: #22c993;
}

.table-goods-top-btn:hover {
    cursor: pointer;
    background-image: linear-gradient(180deg,#e9ecee,#fff);
}

.content-block-div {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.table-orders-null {
    width: auto !important;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8a96a6;
	font-size: 14px;
	font-weight: 400;
    padding: 50px 0;
}

.table-orders-null div {
    width: 400px;
    display: flex;
    text-align: center;
}

#table-bottom {
    width: 100%;
    height: 170px;
    border-top: 1px solid #dee2e6;
    display: flex;
    justify-content: flex-end;
}

#table-bottom-head {
    width: 100%;
    height: 63px;
    background-color: #f4f6f8;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}
.table-bottom-head_h1 {
    color: #636f7f;
	display: inline-flex;
	font-size: 14px;
	font-weight: 500;
	margin-right: 20px;
}
.table-bottom-head_input {
    background-color: #fff;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	color: #1e2248;
	font-size: 14px;
	font-weight: 400;
	height: 30px;
	line-height: 1.42857143;
	outline: none;
	padding: 0 16px;
	width: 72px !important;
}
.table-bottom-head_input:disabled {
    background-color: #e1e1e1;
}
.table-bottom-head_label {
    font-size: 14px;
	font-weight: 400;
    margin-left: 4px;
    margin-right: 16px;
}

#table-bottom-total {
    width: 320px;
    min-height: 100%;
    border-left: 1px solid #dee2e6;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.table-bottom-row {
    width: 100%;
    height: 25px;
    display: flex;
    margin-top: 10px;
}

.table-bottom-key {
    color: #636f7f;
	font-size: 12px;
	font-weight: 400;
    width: 65%;
    display: flex;
    padding-left: 50px;
    align-items: flex-end;
    justify-content: flex-end;
}

.table-bottom-value {
    font-size: 14px;
	font-weight: 400;
    width: 35%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.table-bottom-currency {
    display: flex;
    font-size: 14px;
	font-weight: 400;
    padding: 0 5px;
    align-items: flex-end;
    margin-right: 20px;
}

.table-bottom-key__total {
    font-size: 16px;
	font-weight: 500;
}

.table-orders-col span input {
    width: 50px;
    height: 25px;
    border: 1px solid #dee2e6;
	border-radius: 5px;
	color: #1e2248;
	font-size: 12px;
	padding: 1px 10px;
    text-align: center;
}

.table-orders-col span input[type='number'] {
    -moz-appearance: textfield;
}

.table-orders-col span input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;                     
}

.table-orders-delete {
    width: 20px;
    height: 20px;
    background: url('./img/delete-svgrepo-com.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
}

.table-orders-delete:hover {
    cursor: pointer;
    background: url('./img/delete-svgrepo-com-red.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
}

.content-body-padding {
    padding: 25px;
}

.content-body-padding-70 {
    padding: 25px;
    width: 700px;
}

.table-order-conteiner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    position: relative;
}

.table-status-step-row {
    height: 100% !important;
    display: flex !important;
    align-items: center;
    padding: 0 !important;
}

.table-status-step-col {
    min-width: 100px !important;
    width: 100px;
    flex-grow: 1;
    height: 100%;
    display: flex;
    padding: 0 10px !important;
}

.table-status-step-col__status {
    width: 88px !important;
}

.table-status-step-col__center {
    justify-content: center;
}

.table-products-col {
    width: 16.5% !important;
    min-width: 16.5% !important;
}

.table-products-group-col {
    width: 33% !important;
    min-width: 33% !important;
}
.table-products-group-col__center {
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
}

.table-products-saldo-col {
    width: 33.3% !important;
    min-width: 33.3% !important;
}

.table-provider-col-1 {
    width: 20% !important;
    min-width: 20% !important;
}
.table-provider-col-2 {
    width: 10% !important;
    min-width: 10% !important;
}
.table-provider-col-3 {
    width: 70% !important;
    min-width: 70% !important;
}

.table-receipt-col-1 {
    width: 15% !important;
    min-width: 15% !important;
}
.table-receipt-col-2 {
    width: 15% !important;
    min-width: 15% !important;
}
.table-receipt-col-3 {
    width: 20% !important;
    min-width: 20% !important;
}
.table-receipt-col-4 {
    width: 50% !important;
    min-width: 50% !important;
}

.table-col-10 {
    width: 10% !important;
    min-width: 10% !important;
    display: flex;
}
.table-col-15 {
    width: 15% !important;
    min-width: 15% !important;
    display: flex;
}
.table-col-20 {
    width: 20% !important;
    min-width: 20% !important;
    display: flex;
}
.table-col-25 {
    width: 25% !important;
    min-width: 25% !important;
    display: flex;
}
.table-col-30 {
    width: 30% !important;
    min-width: 30% !important;
    display: flex;
}
.table-col-33 {
    width: 33% !important;
    min-width: 33% !important;
    display: flex;
}
.table-col-35 {
    width: 35% !important;
    min-width: 35% !important;
    display: flex;
}
.table-col-40 {
    width: 40% !important;
    min-width: 40% !important;
    display: flex;
}

.table-align-center {
    justify-content: center !important;
}

.table-status-stem__markup {
    border-radius: 4px;
    margin-top: -25px;
    margin-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
}

.table-foother-count {
    background-color: #f4f6f8;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    color: #727272;
    padding-right: 15px;
}

.table-foother {
    width: 100%;
    height: 50px;
    color: #1e2248;
	font-size: 13px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-foother-count-fix {
    background-color: #f4f6f8;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    color: #727272;
    padding-right: 15px;
}

.table-foother-fix {
    width: 100%;
    height: 50px;
    color: #1e2248;
	font-size: 13px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.good-discount-cell {
    text-decoration: underline;
}

.discont-block {
    display: none;
    position: absolute;
    z-index: 100;
    border: 1px solid #e9ecef;
    width: 100%;
    left: 0;
    height: 35px;
    background-color: #f4f6f9;
    justify-content: center;
}

.discont-block__active {
    display: flex !important;
}

.discont-block-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
}

.discont-block-element label {
    padding-bottom: 3px;
}

.discont-block-element input {
    width: 90px;
    height: 15px;
    border: 1px solid #dfe2e6;
    border-radius: 3px;
    background-color: #fff;
    text-align: center;
    font-size: 12px;
}

.discont-block-element input:focus {
    border: 1px solid #dfe2e6;
}

.discont-block-element input:disabled {
    background-color: #f4f6fa;
}

.discont-block-element__btn-save {
    padding: 5px 10px;
    background-color: #0052cc;
    color: #fff;
    border-radius: 3px;
    margin-top: 7px;
}
.discont-block-element__btn-save:hover {
    cursor: pointer;
}