.filter-zone__hide {
    display: none !important;
}

.filter-zone-row {
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
}

.jsx-parser {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100% !important;
    position: relative !important;
    justify-content: flex-start !important;
}

.filter-zone {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    justify-content: flex-start !important;
}

.filter-zone div {
    display: flex;
}

.filter-zone div div {
    display: flex;
    width: auto;
    flex-direction: column;
}

.filter-zone label {
    font-size: 13px;
    color: #636f7f;
}

.filter-zone input {
    border: 1px solid #dee2e6;
	border-radius: 6px;
	color: #1e2248;
	font-size: 13px;
    width: 200px;
	height: 27px;
	line-height: 26px;
	padding: 1px 10px;
	transition: .2s ease;
    margin-right: 5px;
}

.filter-zone input:focus {
    border: 1px solid #005eeb;
    outline: 3px solid #d1e5f9;
}

.filter-zone input::placeholder {
    color: #b4b7b8;
    font-size: 13px;
}

.filter-btn {
	background-color: #fff;
	background-image: linear-gradient(180deg,#fff,#e9ecee);
	border: 1px solid #dee2e6;
	border-radius: 6px;
	display: inline-block;
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Roboto,sans-serif;
	font-size: 14px;
	font-weight: 500;
	padding: 0 27px;
    width: auto !important;
    margin-top: 25px;
}

.filter-btn:hover {
    cursor: pointer;
    background-image: linear-gradient(180deg,#e9ecee,#e9ecee);
}

.filter-zone select {
    border: 1px solid #dee2e6;
	border-radius: 6px;
	color: #1e2248;
	font-size: 13px;
    width: 222px;
	height: 31px;
	line-height: 26px;
	transition: .2s ease;
    margin-right: 5px;
    appearance:inherit;
    padding: 0 10px;
}

.filter-zone select:focus {
    border: 1px solid #005eeb;
    outline: 3px solid #d1e5f9;
}

.filter-select_placeholder {
    color: #b4b7b8 !important;
}

.filter-element {
    width: 25% !important;
    display: flex !important;
    flex-wrap: wrap !important;
}

.filter-element-row {
    width: 200px !important;
    display: flex !important;
    position: relative;
    flex-direction: row !important;
}
.filter-element-row input {
    margin-right: 5px !important;
    width: 87px !important;
}

.filter-btn-settings {
    width: 36px !important;
    height: 36px;
    display: flex;
    background-image: url('./img/settings.svg');
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 25px;
    border-radius: 5px;
    margin-left: 10px;
}

.filter-btn-settings:hover {
    cursor: pointer;
    background-color: #f4f6f8;
}

#filter-zone-popup {
    width: 100%;
    min-height: 100%;
    background-color: rgb(0, 0, 0, 0.2);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
    display: none;
}

.filter-zone-popup__active {
    display: flex !important;
}

.filter-zone-popup-background {
    z-index: 21;
    width: 840px !important;
    height: calc(100vh - 64px);
	margin-top: 32px;
    margin-left: calc(50% - 420px);
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    display: flex !important;
    border-radius: 5px;
    flex-wrap: wrap;
    align-content: space-between
}

.filter-zone-popup-top {
    display: flex;
    height: 61px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
    justify-content: space-between;
}

.filter-zone-popup-top span {
    font-size: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 25px;
}

.filter-zone-popup-center {
    display: flex;
    overflow-y: auto;
    min-height: calc(100% - 64px - 61px - 60px);
    padding: 20px;
    align-items: center;
}

.filter-zone-popup-bottom {
    display: flex;
    border-top: 1px solid rgb(0, 0, 0, 0.1);
    height: 65px;
    align-items: center;
    justify-content: space-between;
}

#filter-zone-popup__close {
    width: 61px !important;
    border-left: 1px solid rgb(0, 0, 0, 0.1);
    background-image: url('./img/close.svg');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

#filter-zone-popup__close:hover {
    cursor: pointer;
    background-color: #f4f6f8;
}

.filter-zone-popup-button-save {
	background-color: #005eeb;
    background-image: linear-gradient(180deg,#0068ff,#005bd5);
	border-radius: 6px;
	color: #fff;
	display: inline-block;
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Roboto,sans-serif;
	font-size: 14px;
	font-weight: 500;
	padding: 3px 20px;
    height: 32px !important;
    width: auto !important;
    margin-left: 25px;
}

.filter-zone-popup-button-save:hover {
    cursor: pointer;
    background-image: linear-gradient(180deg,#005bd5,#0068ff);
}

.filter-zone-popup-button-reset {
    border-bottom: 1px dotted;
    text-decoration: none;
    color: #005bd5;
    font-size: 12px;
    height: 23px;
    margin-right: 25px;
    margin-top: -10px;
}

.filter-zone-popup-button-reset:hover {
    cursor: pointer;
    border-bottom: 1px solid;
}

.filter-zone-popup-center__left {
    width: 300px !important;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    align-content: flex-start;
}

.filter-zone-popup-center__right {
    display: flex;
    width: auto;
    height: 100%;
    background-color: #f4f6f8;
    padding: 10px;
    flex-wrap: wrap;
    align-content: flex-start;
}

.filter-popup-head {
    color: #636f7f;
	font-size: 12px;
	font-weight: 600;
	margin-top: 7px;
    margin-left: 10px;
}

.filter-popup-element {
    width: 29% !important;
    display: flex !important;
    justify-content: flex-start !important;
    margin-left: 20px;
}
.filter-popup-element label {
    font-size: 12px;
    margin-left: 10px;
    color: #636f7f;
}

.filter-popup-active-element {
    height: 32px;
    display: flex;
    align-items: center;
    color: #636f7f;
    padding-left: 25px;
    flex-wrap: wrap;
}
.filter-popup-active-element:hover {
    cursor: pointer;
    background-color: #f0f1f5;
}
.filter-popup-active-element span {
    width: auto !important;
    display: flex;
}
.filter-popup-active-element div {
    width: 16px;
    height: 16px;
    background-image: url('./img/delete.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    margin-right: 10px;
}

.d-none {
    display: none !important;
}

.filter-element-width {
    width: 230px !important;
    display: flex;
    justify-content: flex-start !important;
}