.customers-column {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}
.customers-column__btn {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-end;
}
.customers-column__right {
    margin-right: 5%;
}

.manager-column__left {
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-right: 5%;
}
.manager-column__right {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}
.manager-column__btn {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-end;
}

.CustomerContainer {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    margin-bottom: 25px;
}

.CustomerContainer__notices {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    margin-bottom: 25px;
    height: 300px;
}
.CustomerContainer__top {
    margin-top: -26px;
    margin-left: 15px;
    width: calc(100% - 20px) !important;
    border-radius: 0 0 4px 4px !important;
}

.customer-container {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
}

.customer-container__not-border {
    border-top: 0px;
}

.customer-container__lable {
    background: #005eeb;
	color: #fff;
	display: inline-block;
	letter-spacing: .1em;
	margin-right: 40px;
	padding: 4px 17px 3px;
	position: relative !important;
	text-transform: uppercase;
    font-size: 10px;
    margin-left: 25px;
    border-radius: 0 0 7px 7px;
}

.customer-container__lable div::before {
    content: "";
    width: calc(100% + 14px);
    height: 5px;
    background-color: #005eeb;
    position: absolute;
    left: -7px;
    top: 0;
    z-index: 4;
}

.customer-container__lable::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    left: -10px;
    top: 0px;
    border-radius: 0 7px 0 0;
    z-index: 5;
}

.customer-container__lable::after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    right: -10px;
    top: 0px;
    border-radius: 7px 0 0 0;
    z-index: 5;
}

.profile-contant__top {
    margin-top: 15px;
    width: 100% !important;
    height: 52px;
    display: flex !important;
    align-items: center;
    padding-left: 25px;
    flex-wrap: nowrap !important;
    position: relative;
}

.profile-contant__mb25 {
    margin-bottom: 25px;
}

.profile-contant__top-v2 {
    width: 100% !important;
    height: 52px;
    display: flex !important;
    align-items: center;
    padding-left: 25px;
    flex-wrap: nowrap !important;
    background-color: rgb(249, 250, 251);
	border-bottom: 1px solid #dee2e6;
}

.profile-contant__top-data {
    margin-top: 15px;
    width: 100% !important;
    display: flex !important;
    align-items: center;
    padding-left: 25px;
    flex-wrap: wrap !important;
}

.profile-img {
    width: 52px !important;
    height: 52px;
    border-radius: 50%;
    background-color: #afb9c3;
}

.profile-img img {
    width: 52px !important;
    height: 52px;
    border-radius: 50%;
    background-color: #afb9c3;
}

.profile-text {
    width: 100% !important;
    margin-left: 10px;
    display: flex !important;
    flex-wrap: nowrap !important;
    flex-direction: column;
}

.profile-text span{
    display: flex !important;
}

.profile-text-customer {
    width: 100% !important;
    margin-left: 10px;
    display: flex !important;
    flex-wrap: nowrap !important;
    flex-direction: column;
}

.profile-text-customer span{
    display: flex !important;
}

.profile-text-customer div{
    display: flex !important;
}

.profile-text-customer span::before {
    content: "";
    width: 15px;
    height: 15px;
    background: url('./img/sex.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-flex;
    margin-right: 5px;
}

.customers-icon__active {
    background-color: #dee2e6 !important;
}

.customers-icon {
    position: relative;
    width: 25px !important;
    margin: 0 10px;
    left: calc(50% - 60px);
	margin-top: -45px;
    padding: 5px 10px;
    border-radius: 10px;
}
.customers-icon span:hover {
    cursor: pointer;
}

.customers-icon span {
    width: 20px;
    height: 8px;
    margin-left: 3px;
}

.customers-icon__vip {
    background: url('../Orders/elements/img/vip.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.customers-icon__bad {
    background: url('../Orders/elements/img/bad.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.customers-icon__vip:hover {
    background: url('../Orders/elements/img/vip_active.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.customers-icon__bad:hover {
    background: url('../Orders/elements/img/bad_active.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.customers-bottom {
    height: 75px;
    width: 100%;
    background-color: rgb(249, 250, 251);
    border-top: 1px solid #dee2e6;
    display: flex;
    align-items: center;
}

.customers-bottom__left {
    font-size: 13px;
    color: rgb(99, 111, 127);
    width: 150px;
    padding-left: 25px;
}
.customers-bottom__right {
    width: calc(100% - 195px);
    font-size: 13px;
    padding-left: 20px;
    display: flex;
    align-items: flex-start;
}

.manager-bottom {
    height: 125px;
    width: 100%;
    background-color: rgb(249, 250, 251);
    border-top: 1px solid #dee2e6;
    display: flex;
    align-items: center;
}

.manager-bottom__activity {
    width: 100%;
    background-color: rgb(249, 250, 251);
    border-top: 1px solid #dee2e6;
    display: flex;
    align-items: center;
}

.manager-bottom__left {
    font-size: 13px;
    color: rgb(99, 111, 127);
    width: 150px;
    padding-left: 25px;
}
.manager-bottom__right {
    width: calc(100% - 195px);
    font-size: 13px;
    padding-left: 20px;
    display: flex;
    align-items: flex-start;
}

.customers-tag {
    width: 100%;
    border-top: 1px solid #dee2e6;
    min-height: 50px;
}

.customers-data-container {
    width: 45% !important;
    display: flex !important;
    margin-bottom: 25px;
}

.manager-data-container {
    width: 100% !important;
    display: flex !important;
    margin-bottom: 25px;
}

.customers-data {
    display: flex;
    flex-wrap: wrap;
}

.customers-data-value {
    font-size: 18px;
	font-weight: 700;
}

.customers-data-rub {
    font-size: 18px;
	font-weight: 400;
    margin-left: 5px;
}

.customers-data-description {
    color: #636f7f;
	font-size: 11px;
    margin-top: 5px;
}

.customer-notises-head {
    height: 100%;
    width: auto !important;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.customer-notises-head__label-active {
    display: flex;
    align-items: center;
    color: #005eeb;
    font-weight: 500;
    border-bottom: 3px solid #005eeb;
    height: 100%;
    width: 100%;
}
.customer-notises-head__label-active:hover {
    cursor: pointer;
}

.customer-notises-head__label {
    color: #8a96a6;
    font-weight: 400;
    padding: 5px 15px;
    border-radius: 10px;
}
.customer-notises-head__label:hover {
    cursor: pointer;
    color: #005eeb;
    background-color: #b1cbf1;
}

.customer-notises-textarea {
    margin: 25px 25px 25px 0;
    resize: none;
    width: 100%;
    height: 150px;
	margin-top: 130px;
    font-size: 16px;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid #dee2e6;
}

.customer-notises-textarea:focus {
    outline: none;
}

.customer-notices-buttom {
    width: 110px !important;
    margin-top: 125px;
    margin-left: 25px;
}

.customer-lable {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 11px;
	font-weight: 700;
	text-transform: uppercase;
    color: #636f7f;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.customer-order-top {
    width: 100% !important;
    height: 55px;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    flex-wrap: wrap !important;
    align-items: center;
    justify-content: space-between !important;
}
.customer-order-body {
    width: 100%;
    display: flex;
}
.customer-order-top__column {
    width: 50% !important;
    display: flex;
}
.customer-order-top__column-close {
    width: 8px !important;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #8a96a6;
    margin-right: 25px;
}
.customer-order-top__column-active {
    cursor: pointer;
    background-color: #22c993;
    border: 1px solid #22c993;
}
.customer-order-top__column-close:hover {
    cursor: pointer;
    background-color: #22c993;
    border: 1px solid #22c993;
}
.customer-order-top__column-right {
    width: calc(50% - 25px) !important;
    display: flex;
    justify-content: flex-end !important;
    margin-right: 25px;
    color: #636f7f;
	font-size: 12px;
	font-weight: 400;
}
.customer-order-top span {
    font-weight: 500;
    font-size: 14px;
    margin-right: 5px;
    margin-left: 25px;
}
.customer-order-top a {
    color: #0052cc;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    margin-right: 10px;
}
.customer-order-top a:hover {
    text-decoration: underline;
    cursor: pointer;
}
.customer-order-top__status {
    width: auto !important;
    padding: 3px 15px;
    background-color: #0052cc;
    border-radius: 4px;
    font-weight: 500;
    font-size: 11px;
    max-width: 99px;
}
.customer-order-body__good {
    width: 100% !important;
    height: auto !important;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 0;
    margin-left: 25px;
    margin-right: 25px;
}
.customer-order-body__good-name {
    width: 46% !important;
    color: #0052cc;
    text-decoration: none;
    font-size: 13px;
}
.customer-order-body__good-name:hover {
    cursor: pointer;
}
.customer-order-body__good-value {
    font-size: 13px;
    width: 18% !important;
    display: flex;
    justify-content: flex-end !important;
}
.customer-order-body__good-note {
    font-size: 13px;
    width: calc(100% - 16px) !important;
    display: flex;
}
.customer-order-body__good-note-close {
    width: 14px !important;
    height: 14px;
    border-radius: 50%;
    background: url('./img/delete-svgrepo-com.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.customer-order-body__good-note-close:hover {
    cursor: pointer;
    background: url('./img/delete-svgrepo-com-red.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.customer-order-body__good-discount {
    width: 100% !important;
    display: flex;
    justify-content: flex-end !important;
    font-size: 13px;
    color: #636f7f;
    margin-top: -10px;
    margin-bottom: 2px;
}
.customer-order-body__good-total {
    width: 100% !important;
    display: flex;
    justify-content: flex-end !important;
    font-size: 13px;
    font-weight: bold;
}

.customer-order-body__task {
    width: auto !important;
    color: #333;
    font-size: 12px;
}

.customer-order-body__task-user {
    color: #005eeb;
    text-decoration: none;
}
.customer-order-body__task-user:hover {
    cursor: pointer;
    text-decoration: underline;
}
.customer-order-body__task-user::before {
    content: "";
    margin-left: 10px;
    margin-right: 5px;
    margin-top: 3px;
    width: 10px;
    height: 10px;
    background: url('./img/user.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.customer-order-body__task-order {
    color: #005eeb;
    text-decoration: none;
}
.customer-order-body__task-order:hover {
    cursor: pointer;
    text-decoration: underline;
}
.customer-order-body__task-order::before {
    content: "";
    margin-left: 10px;
    margin-right: 5px;
    margin-top: 3px;
    width: 10px;
    height: 10px;
    background: url('./img/order.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.order-top {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
}

.order-links {
    width: auto !important;
}

.customer-edit-container {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid #e9ecee;
}

.customer-edit-container__not-border {
    border-top: 0px;
}

.customer-edit-container__lable {
    background: #005eeb;
	color: #fff;
	display: inline-block;
	letter-spacing: .1em;
	margin-right: 40px;
	padding: 4px 17px 3px;
	position: relative;
	text-transform: uppercase;
    font-size: 10px;
    margin-left: 25px;
    border-radius: 0 0 7px 7px;
}

.customer-edit-container__lable div::before {
    content: "";
    width: calc(100% + 14px);
    height: 5px;
    background-color: #005eeb;
    position: absolute;
    left: -7px;
    top: 0;
    z-index: 4;
}

.customer-edit-container__lable::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    left: -10px;
    top: 0px;
    border-radius: 0 7px 0 0;
    z-index: 5;
}

.customer-edit-container__lable::after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    right: -10px;
    top: 0px;
    border-radius: 7px 0 0 0;
    z-index: 5;
}