.supply-table {
    display: flex;
    flex-direction: column;
}

.supply-top {
    display: flex;
    flex-direction: row-reverse;
}

.supply-button {
    width: auto !important;
	padding: 6px 24px;
    margin-right: 20px;
	background-color: #005eeb;
	background-image: linear-gradient(180deg, #0068ff, #005bd5);
	border: none;
	border-radius: 6px;
	color: #fff;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Roboto, sans-serif;
	font-size: 14px;
	font-weight: 400;
}
.supply-button:hover {
    cursor: pointer;
}
.supply-button__active {
	background-image: linear-gradient(180deg, #c3daff, #abcbff);
    color: #0068ff;
}