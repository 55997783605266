.manager-link-activity {
    color: #005eeb;
	font-weight: 400;
    text-decoration: none;
    font-size: 13px;
    width: auto !important;
    margin-right: 15px;
}
.manager-link-activity:hover {
    cursor: pointer;
    color: #005eeb;
    text-decoration: underline;
}
.manager-link-activity__active {
    color: #424242;
}

.manager-order-container {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    padding: 5px 0;
}
.manager-order-container__element {
    width: auto !important;
    padding: 5px 15px;
    font-size: 13px;
}
.manager-order-container__link {
    width: auto !important;
    padding: 5px 15px;
    font-size: 13px;
    text-decoration: none;
    color: #005eeb;
}