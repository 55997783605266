.MenuSecond {
    width: 0px;
    min-height: 100vh;
    background-color: transparent;
    transition: width .25s ease;
    transition-delay: 0.5s;
    flex-grow: 0;
    opacity: 0;
    padding: 20px 8px 0;
    overflow-y: auto;
    display: flex !important;
    flex-shrink: 0;
}

.MenuSecond div {
    width: 100%;
}

.MenuSecond__active {
    width: 256px;
    opacity: 1;
    display: flex;
}

.MenuSecondContainer {
    padding: 16px 8px;
    margin: 0 -8px;
}