.ContantContainer {
    background-color: #f4f6f8;
    border-radius: 18px 0 0 0;
    min-height: 100vh;
    flex-grow: 1;
    margin-left: 16px;
    box-shadow: 0 2px 8px rgba(30,34,72,.16);
    overflow-y: auto;
}

.ContantContainer-along {
    margin-left: 0px;
}

.ContantContainer__button-show {
    display: flex;
    width: 22px;
    height: 22px;
    margin: 20px 0 0 -11px;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='%23636F7F'%3E%3Cpath fill-rule='evenodd' d='M19 9.75 17.166 8 10 15l7.166 7L19 20.25 13.6 15 19 9.75z' clip-rule='evenodd'/%3E%3C/svg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 16px;
    border: 1px solid #f4f6f8;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(30,34,72,.16);
    transform: rotate(180deg);
    position: absolute;
    z-index: 1;
}

.ContantContainer__button-show:hover {
    cursor: pointer;
    background-color: #f4f6f8;
    border: 1px solid #4191ff;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='%234191ff'%3E%3Cpath fill-rule='evenodd' d='M19 9.75 17.166 8 10 15l7.166 7L19 20.25 13.6 15 19 9.75z' clip-rule='evenodd'/%3E%3C/svg%3E");
    transition: .25s ease;
    transition-delay: 0.5s;
}

.ContantContainer__button-show-active {
    transform: rotate(360deg);
    transition: .25s ease;
    transition-delay: 0.5s;
}

.ContantContainer-left {
    width: 100%;
    min-height: 100vh;
    background-color: black;
    opacity: 0;
    visibility: hidden;
    display: flex;
    position: absolute;
    right: 0px;
    top: 0px;
    transition: 1s ease;
}

.ContantContainer-left__active {
    width: 100%;
    opacity: 0.1;
    visibility: visible;
}

.Contant-left {
    width: 50%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: -100vw;
    top: 0px;
    background-color: #fff;
    opacity: 0;
    transition: width 0.5s ease;
}

.Contant-left__profile {
    width: 420px !important;
}

.Contant-left__active {
    left: 0px;
    opacity: 1;
}