* {
  padding: 0px;
  margin: 0px;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Roboto,sans-serif;
}

html, body, #root
{
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Roboto,sans-serif;
  overflow-y: hidden;
  overflow-x: hidden;
  min-width: 1150px;
}

#root {
  display: flex;
}

.root-ligth {
  background-color: #f4f6f8;
}

.root-dark {
  background-color: #1e2248;
}

.d-none {
  display: none;
}

.a-none {
  transition: 0s ease;
  transition-delay: 0s;
}

.container {
	width: 100%;
	height: 100%;
	display: flex;
}

.v-none {
  visibility: hidden;
  opacity: 0;
}

.Component {
  width: fit-content;
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.padding-bottom-none {
  padding-bottom: 0 !important;
}

.preloader-block {
  width: 100%;
  min-height: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader-block__fix {
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  background-color: rgb(0, 0, 0, 0.1);
}

.loader {
  width: 48px !important;
  height: 48px;
  border: 5px solid #919191;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  } 

  .my-10 {
    margin-top: 10px;
    margin-bottom: 10px;

  }

  .validation-field {
    border: solid 1px #ff0000 !important;
  }

  .active-element {
    width: 24px !important;
    height: 24px !important;
    background: url('/img/active.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }