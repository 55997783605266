:root {
    --color-border: #dee2e6;
    --color-label: #636f7f;
    --color-input-text: #1e2248;
    --border-radius-input: 6px;
    --text-size-input: 13px;
}

.input-horizontal {
    width: 100% !important;
    display: flex !important;
    flex-wrap: nowrap !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    align-items: center !important;
}
.input-horizontal__label {
    font-size: 13px;
    color: var(--color-label);
    width: 150px;
    min-width: 150px;
}
.input {
    border: 1px solid var(--color-border);
	border-radius: var(--border-radius-input);
	color: var(--color-input-text);
	font-size: var(--text-size-input);
    width: calc(100% - 177px);
    height: 27px;
	line-height: 19px;
	padding: 1px 10px;
	transition: .2s ease;
    margin-right: 5px;
}
.input:focus {
    border: 1px solid #005eeb;
    outline: 3px solid #d1e5f9;
}
.input::placeholder {
    color: var(--color-border);
}
.input-horizontal__input {
    width: calc(100% - 177px);
}
.input-horizontal__input-range {
    width: 100% !important;
}
.input-horizontal__container-range {
    display: flex !important;
    flex-wrap: nowrap !important;
}
.input-horizontal__select {
    width: 100% !important;
	height: 31px !important;
    padding: 4 10px !important;
}
.input-horizontal-select__hold {
    color: var(--color-border) !important;
}
.input-horizontal__textarea {
    height: 55px;
    resize: none;
    padding: 5px 10px;
}