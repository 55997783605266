.document {
    width: 100%;
    display: flex;
    overflow-y: auto;
    justify-content: center;
    flex-wrap: wrap;
}

.document-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.document-buttoms {
    width: 850pt;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
}

.document-buttom {
    display: flex;
    padding: 10px 25px !important;
    border-radius: 5px;
    background-color: rgb(0, 94, 235);
    color: #fff;
    font-size: 14px;
    margin-top: 15px;
}

.document-buttom:hover {
    cursor: pointer;
}