.popup-area {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center !important;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw !important;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.2);
    z-index: 1;
}

.popup-area__form {
    width: 100% !important;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    z-index: 2;
}

.popup-area-form__top {
    width: 100% !important;
    height: 50px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end !important;
    align-items: center;
}

.popup-area-form-top__button {
    width: 25px !important;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.popup-area-form-top__button:hover {
    cursor: pointer;
}

.popup-button {
	background-color: #005eeb;
	background-image: linear-gradient(180deg, #fff, #e9ecee);
	border: 1px solid #dee2e6;
	border-radius: 6px;
	color: #1e2248;
	display: flex;
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Roboto,sans-serif;
	font-size: 16px;
	font-weight: 500;
	height: 36px;
	padding: 8px 20px;
	text-decoration: none;
	user-select: none;
    width: auto !important;
    min-width: max-content;
    margin-top: 5px;
    line-height: 35px !important;
    margin-right: 25px;
}

.popup-button::before {
    content: "";
    height: 24px;
	width: 24px;
    background-image: url('./import.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
}

.popup-button:hover {
    cursor: pointer;
    background-image: linear-gradient(180deg, #e9ecee, #e9ecee);

}

.popup-area-form__middle {
    padding: 25px;
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
}

.popup-area-form-middle__area {
    border: 1px solid #005eeb;
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(30, 34, 72, .16);
    width: 100% !important;
    max-width: calc(100%);
    height: 100%;
    display: flex;
    align-content: flex-start;
    overflow-y: scroll;
    flex-direction: column;
}

.popup-area-form-middle__title {
    color: #005eeb;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
    padding: 25px;
    display: flex;
    flex-wrap: nowrap;
}

.popup-area-form-middle__title::before {
    content: "";
    display: block;
    background-image: url('./icons.6ec35068.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 15px;
    height: 45px;
    padding-right: 25px;
    margin-top: -2px !important;
    margin-left: 15px;
    margin-top: 3px;
}

.popup-area-form-middle__description {
    display: flex;
    justify-content: center !important;
    font-size: 14px;
    color: #1e2248;
    text-align: center;
    padding: 25px;
}

.popup-area-form-middle__input {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    margin: auto;
    max-width: 100%;
    position: relative;
    width: 100%;
    margin: 25px;
    border: 1px solid rgb(199, 205, 212);
    border-radius: 6px;
}
.popup-area-form-middle__input:hover {
    border: 1px solid #005eeb;
}

.popup-area-form-middle__input input {
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
}
.popup-area-form-middle__input input:hover {
    cursor: pointer;
}

.popup-area-form-middle__input label {
    position: absolute;
    color: rgb(0, 94, 235);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    z-index: 5;
    width: 100%;
    text-align: center;
}

.popup-area-form-middle__next {
    display: flex;
    padding: 8px 25px !important;
    border-radius: 5px;
    background-color: rgb(0, 94, 235);
    color: #fff;
    width: auto !important;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    margin-top: 15px;
}

.popup-area-form-middle__next:hover {
    cursor: pointer;
}

.popup-area-form-middle__table {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.popup-area-form-middle__col1 {
    width: 350px !important;
    padding: 25px;
    flex-wrap: wrap;
}

.popup-area-form-middle__col2 {
    width: calc(100% - 350px) !important;
    max-width: calc(100% - 250px) !important;
    padding: 25px;
    display: flex;
    flex-wrap: nowrap;
}

.popup-area-form-middle-col__field-row {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;

}

.popup-area-form-middle-col__field {
    width: 120px;
    min-width: 120px;
    height: 40px;
    border: 1px solid rgb(0, 94, 235);
    border-radius: 5px;
    margin: 10px;
    font-size: 11px;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}

.popup-area-form-middle-col__field-special {
    background-color: #9dff00;
}

.popup-area-form-middle-col-field__active {
    border: 1px solid #66a500 !important;
    background-color: #9dff00 !important;
    color: #66a500 !important;
}

.popup-area-form-middle__area-fields {
    margin: 10px;
    border-radius: 5px;
    background-color: rgb(241, 247, 255);
    padding: 10px;
    font-size: 12px;
    flex-wrap: wrap;
    width: 100% !important;
    display: flex;
    
}

.popup-area-form-middle-col__element {
    width: 100px !important;
    min-width: 60px;
    height: 25px;
    border: 1px solid rgb(205, 147, 0);
    background-color: rgb(255, 209, 6);
    color: rgb(205, 147, 0);
    border-radius: 5px;
    margin: 10px;
    font-size: 9px;
    display: flex;
    justify-content: center !important;
    align-items: center;
}

.popup-area-form-middle-col__element {
    cursor: pointer;
}

.popup-area-form-middle__dict {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    height: 15px;
}

.popup-area-form-middle-dict__row {
    display: flex;
    flex-wrap: nowrap;
    padding: 10px;
}

.popup-area-form-middle-dict__row label {
    margin-left: 15px;
}

.imported-data-table {
    width: calc(100% - 50px) !important;
    display: flex;
    flex-direction: column;
    padding: 0 25px 25px 25px;
    overflow-y: scroll;
}

.imported-data-row {
    width: 100% !important;
    height: auto;
    display: flex;
    flex-wrap: nowrap !important;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e3e3e3;
    font-size: 12px;
    font-weight: 400;
}

.imported-data-row__column-good {
    width: 16% !important;
    min-height: 35px;
    display: flex;
    justify-content: center !important;
    align-items: center;
}

.imported-data-row__column-small {
    width: 100px !important;
    min-height: 35px;
    display: flex;
    justify-content: center !important;
    align-items: center;
}

.imported-data-row__column {
    width: calc(50% - 100px) !important;
    min-height: 35px;
    display: flex;
    align-items: center;
}

.imported-data-row-column__head {
    font-weight: 600;
}

.imported-data-buttons {
    width: calc(100% - 50px);
    display: flex;
    flex-wrap: nowrap;
    padding: 0 25px 25px 25px;
}

.imported-data-button__left {
    margin-left: 0px !important;
}
.imported-data-button__right {
    margin-right: 0px !important;
}