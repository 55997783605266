.MenuSecondLinks {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column
}

.MenuSecondHeader {
    display: flex;
    align-content: center;
}

.MenuSecondSubLink {
    display: none !important;
    transition: 0.25s ease;
}

.MenuSecondSubLink__active {
    display: flex !important;
    background-color: #fff !important;
}

.MenuSecondLink {
    display: flex;
    height: 50px;
    background-color: transparent;
    border-radius: 4px;
    align-items: center;
    font-size: 16px;
    line-height: 1.5;
    text-overflow: ellipsis;
    height: 48px;
    padding: 0 16px;
    font-weight: 400;
    text-decoration: none;
    color: #000;
    transform: 0s;
}

.MenuSecondLink:hover {
    background-color: #e9ecef;
    cursor: pointer;
}

.MenuSecondLink__active {
    box-shadow: 0 2px 4px rgba(30,34,72,.16) !important;
    color: #fff !important;
    background-color: #005eeb !important;
}

.MenuSecondLink__active::before {
    width: 0;
    height: 0;
}

.MenuSecondSubLink {
    font-size: 14px;
}

.MenuSecondSubLink::before {
    content: "";
    background: #c7cdd4;
	border-radius: 50%;
	height: 4px;
	left: 21px;
	margin: 10px;
	top: 18px;
	transition: .25s ease;
	width: 4px;
}

.MenuSecondHeaderBlock {
    display: flex;
    justify-content: space-between;
}

.MenuSecondHeaderLink {
    width: 32px !important;
    height: 32px !important;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    padding: 0 4px;
}

.MenuSecondHeaderLink:hover {
    cursor: pointer;
    background-color: #005eeb;
}

.MenuSecondHeaderLink svg {
    width: 24px;
    height: 24px;
    fill: #c7cdd4;
}

.MenuSecondHeaderLink svg:hover {
    fill: #fff;
}

.MenuSecondBlock__active {
    background-color: #fff !important;
}