.user-setting-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid #e9ecee;
}

.user-setting-container__not-border {
    border-top: 0px;
}

.user-setting-container__lable {
    background: #005eeb;
	color: #fff;
	display: inline-block;
	letter-spacing: .1em;
	margin-right: 40px;
	padding: 4px 17px 3px;
	position: relative;
	text-transform: uppercase;
    font-size: 10px;
    margin-left: 25px;
    border-radius: 0 0 7px 7px;
}

.user-setting-container__lable div::before {
    content: "";
    width: calc(100% + 14px);
    height: 5px;
    background-color: #005eeb;
    position: absolute;
    left: -7px;
    top: 0;
    z-index: 4;
}

.user-setting-container__lable::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    left: -10px;
    top: 0px;
    border-radius: 0 7px 0 0;
    z-index: 5;
}

.user-setting-container__lable::after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    right: -10px;
    top: 0px;
    border-radius: 7px 0 0 0;
    z-index: 5;
}

.user-setting-email-box {
    background-color: #95d04226;
    width: 100px !important; 
	border-radius: 3px;
	color: #1e2248;
	font-size: 14px;
	padding: 10px 16px 10px 42px;
	position: relative;
	vertical-align: top;
	white-space: nowrap;
    margin: 5px 0 15px 130px;
}
.user-setting-email-box::before {
    background-image: url('./img/check-mark-svgrepo-com.svg');
    background-position: 50%;
	background-repeat:no-repeat;
    background-size: 80%;
	bottom: 0;
	content: "";
    width: 20px;
	height: 20px;
	position: absolute;
	left: 13px;
	top: 0;
    margin: auto;
}

@media (max-width: 1070px) {

    .user-setting-email-box {
        margin: 5px 0 15px 0px;
    }
}

.user-setting-row_line {
    width: 100%;
    padding-left: 25px;
}

.user-settin__description {
    width: max-content;
}