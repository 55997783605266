.PasswordPage {
    width: 100%;
    min-height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
}

.password-col-1 {
    width: 40%;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.password-col-2 {
    width: 60%;
    min-height: 100%;
    display: flex;
}

@media (max-width: 1070px) {
    .password-col-1 {
        width: 100%;
    }
    
    .password-col-2 {
        display: none;
    }
}

.password-page-image {
    width: 100%;
    min-height: 100%;
    background-image: url('./img/login__decor_ru-8246b20a.svg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
}

.password-top {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
}

.password-top a {
    color: #0052cc;
	text-decoration: none;
	font-size: 14px;
    margin-left: 50px;
}

.password-top a:hover {
    cursor: pointer;
    text-decoration: underline;
}

.password-contant {
    width: 400px;
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
}

.password-contant-top {
    font: 800 40px/44px Gilroy,Arial,Helvetica,sans-serif;
	text-align: center;
	margin-top: 24px;
	margin-bottom: 50px;
    display: flex;
    justify-content: center;
}

.password-contant input {
    width: 377px;
    height: 36px;
    border: 1px solid #dee2e6;
	padding: 5px 16px;
    border-radius: 6px;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
    outline: none;
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
	box-shadow: none;
    margin-bottom: 25px;
}

.password-contant input:hover {
    border: 1px solid #c3c7cb;
}

.password-contant input:focus {
    border: 1px solid #005eeb;
    outline: 3px solid #d1e5f9;
}

.re-password__wrong {
    border: 1px solid red !important;
}

#password-btn {
    display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 45px;
    background-color: #005EEB;
    font-size: 16px;
    color: #fff;
    border: 0;
    border-radius: 5px;
    margin-bottom: 25px;
}

#password-btn:hover {
    cursor: pointer;
}

#passowrd-success {
    padding: 10px 25px;
    background-color: #d6f8a4;
    border-radius: 5px;
    display: none;
    margin: auto;
    text-align: center;
    transition: all 0.5s ease;
}

.passowrd-success__active {
    display: flex !important;
}

#passowrd-wrong {
    padding: 10px 25px;
    background-color: #f8b9a4;
    border-radius: 5px;
    display: none;
    margin: auto;
    text-align: center;
    transition: all 0.5s ease;
}

.passowrd-wrong__active {
    display: flex !important;
}

#passowrd-weak {
    padding: 10px 25px;
    background-color: #f8b9a4;
    border-radius: 5px;
    display: none;
    margin: auto;
    text-align: center;
    transition: all 0.5s ease;
}