.Profile {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 11;
    background-color: #fff;
}

.profile-top {
    min-width: calc(100% - 56px);
    height: 68px;
    display: flex;
    border-bottom: 1px solid #dee2e6;
    flex: 0 1 68px;
    justify-content: space-between;
    padding: 0 24px 0 32px;
    flex-wrap: wrap;
    align-items: center;
}

.profile-top__text {
    color: #0d0f1f;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33333333;
}

.profile-contant {
    min-width: calc(100% - 56px);
    height: calc(100% - 123px);
    display: flex;
    justify-content: center;
    padding: 0 24px 0 32px;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
}

.profile-foother {
    min-width: calc(100% - 106px);
    height: 75px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dee2e6;
    flex-wrap: wrap;
    align-items: center
}

.profile-foother__container {
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: -20px;
}

.profile-foother__container-left {
    width: 60%;
}

.profile-foother__container-rigth {
    width: 40%;
}


.profile-foother__container div {
    display: flex;
    align-items: center;
}

.profile-foother__container div svg {
    margin-right: 5px;
    fill: #afb9c3;
}

.profile-foother__container-end {
    justify-content: end;
}

.profile-foother__container div svg:hover {
    cursor: pointer;
}

.profile-foother__container-link {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Roboto,sans-serif;
    color: #0d0f1f;
    text-decoration: none;
}

.profile-foother__container-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.profile-foother__container div span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.profile-contant__top {
    margin-top: 15px;
    width: 352px;
    height: 52px;
    display: flex;
    align-items: center;
}

.profile-img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: #afb9c3;
}

.profile-img img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: #afb9c3;
}

.profile-text {
    margin-left: 10px;
}

.profile-text h5 {
    font-size: 24px;
	font-weight: 500;
	line-height: 1.33333333;
}

.profile-text span {
    color: #636f7f;
	font-size: 12px;
	font-weight: 400;
	line-height: 1.16666667;
	margin-top: 2px;
	min-height: 14px;
}

.profile-contant__status {
    width: 352px;
    height: 50px;
    display: flex;
    justify-content: space-between;
	margin: -1px;
	min-height: 50px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    margin-top: 25px;
}

.profile-contant__col {
	border-radius: 4px;
	color: #0d0f1f;
	cursor: pointer;
	font-size: 12px;
	font-weight: 400;
	line-height: 1.16666667;
	min-width: 0;
	overflow: hidden;
	padding: 25px 8px 7px;
	position: relative;
	text-align: center;
	text-overflow: ellipsis;
    width: 20%;
    font-size: 12px;
	font-weight: 400;
	line-height: 1.16666667;
}

.profile-contant__col::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: flex;
    position: absolute;
    left: 50%;
    margin-top: -15px;
}

.profile-contant__col-1::before {
    background-color: #22c993;
}

.profile-contant__col-2::before {
    background-color: #ff5353;
}

.profile-contant__col-3::before {
    background-color: #ef8e06;
}

.profile-contant__col-4::before {
    background-color: #ffe1ba;
}

.profile-contant__col:hover {
    background-color: #edf4ff;
}

.profile-contant__active {
    background-color: #edf4ff;
    border: 1px solid #0052cc;
}