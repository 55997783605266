.Element {
    background: #f4f6f8;
    width: auto !important;
    min-height: 100%;
    display: flex;
    padding: 40px;
    flex-wrap: wrap;
    align-content: flex-start;
}

.element-top {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column !important;
}

.element-links {
    color: #636f7f;
	font-size: 12px;
    margin-bottom: 5px;
    width: auto !important;
    justify-content: flex-start !important;
}

.element-links a {
    color: #636f7f;
	font-size: 12px;
    text-decoration: none;
}

.element-links a:hover {
    color: #0052cc;
    text-decoration: underline;
    cursor: pointer;
}

.element-links span {
    padding: 0 5px;
}

.element-h1 {
    font-size: 24px;
	font-weight: 500;
    margin-bottom: 40px;
    display: flex;
    align-items: flex-end;
}

.element-h1-btn {
    font-size: 24px;
	font-weight: 500;
    height: 60px;
    margin-bottom: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

#element-save__success {
    width: auto;
    padding: 9px 20px;
    border-radius: 7px;
    background-color: #d6f8a4;
    margin-left: 25px;
    transition: all 0.5s ease;
}

#element-save__wrong {
    width: auto;
    padding: 9px 20px;
    border-radius: 7px;
    background-color: #f8b9a4;
    margin-left: 25px;
    transition: all 0.5s ease;
}

.element-foother {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.element-save__active {
    display: flex !important;
}