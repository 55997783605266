.product-characterictic {
    display: flex;
}

.product-characterictic div{
    width: 100%;
    display: flex;
}

.product-characterictic-area {
    width: 100%;
    display: flex;
    justify-content: center;
}
.product-characterictic-button {
    padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid #dfdfdf;
}
.product-characterictic-button:hover {
    cursor: pointer;
}

.product-images-block {
    display: flex;
    flex-wrap: wrap;
}
.product-images-block__h1 {
    color: #636f7f;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 8px;
}

.product-images-block__area {
    border-radius: 10px;
    border: 1px solid #b0b2b5;
    width: 500px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
}
.product-images-block__area:hover {
    cursor: pointer;
    border: 1px solid #1061cb;
}

.product-images-block__input {
    width: 100%;
    height: 100%;
    display: none;
}
.product-images-block__label {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.product-images-block__label:hover {
    cursor: pointer;
}
.product-images-block-lable__img {
    width: 32px;
    height: 40px;
    border-radius: 2px;
    margin-bottom: 15px;
}
.product-images-block-lable__h1 {
    color: #005eeb;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
}
.product-images-block-lable__h2 {
    color: #8a96a6;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.16666667;
    position: relative;
    z-index: 1;
    margin-top: 10px;
}