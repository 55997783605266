.Login {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    background-color: #fff;
}

.login-top {
    height: 65px;
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.login-top__logo {
    width: 146px;
    background: url('./img//logo_horiz.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-left: 20px;
}

.login-top__phone {

}

.login-top-heading {
    width: 100%;
    height: 160px;
    background-color: #0d0f1f;
    background-image: url('./img//sec1-bg.08a0374e.svg');
    background-position: left 32px bottom;
    background-repeat: no-repeat;
    background-size: auto 87%;
    display: flex;
    align-items: center;
}

.login-top-heading__text {
    color: #fff;
    font-size: 64px;
    font-family: Gilroy,Arial,Helvetica,sans-serif;
    font-weight: 700;
    margin-left: 75px;
}

.login-form {
    width: 592px;
    height: 213px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px 0;
    flex-wrap: wrap;
}

@media screen and (max-width: 450px) {
    html, body, #root {
        min-width: 100vw;
        max-width: 100%;
    }

    .login-top-heading {
        height: 125px;
    }

    .login-top-heading__text {
        font-size: 32px;
    }

    .Login {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .login-form {
        width: 100%;
        display: flex;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        width: 100% !important;
        padding: 0px 30px 0px 30px;
    }

    .form-group button {
        margin-top: 25px !important;
    }

    .form-lable {
        font-size: 22px !important;
        margin-top: 15px;
        margin-bottom: 10px;
        justify-content: center;
        width: 100% !important;
    }

    .form-input {
        width: calc(100% - 60px) !important;
    }
}

.form-group {
    display: flex;
    width: 100%;
    justify-content: center;
}

.form-lable {
    width: 176px;
    height: 24px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
}

.form-input {
    width: 377px;
    height: 36px;
    border: 1px solid #dee2e6;
	padding: 0 16px;
    border-radius: 6px;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
    outline: none;
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
	box-shadow: none;
}

.form-input:hover {
    border: 1px solid #c3c7cb;
}

.form-input:focus {
    border: 1px solid #005eeb;
    outline: 3px solid #d1e5f9;
}

.form-btn {
    width: 135px;
    height: 45px;
    border: 0px;
    border-radius: 50px;
    background-color: #005eeb;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 400;
    font-size: 16px;
	line-height: 1.5;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-btn:hover {
    cursor: pointer;
}

.login-validation {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff5353;
	font-size: 14px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 400;
    margin-top: 5px;
}