.Notification {
    width: 100%;
    background-color: #fff;
    z-index: 50000 !important;
}

.notification__top {
    display: flex;
    border-bottom: 1px solid #dee2e6;
    flex: 0 1 68px;
    justify-content: space-between;
    min-height: 68px;
    padding: 0 24px 0 32px;
}

.notification__top-left {
    width: calc(100% - 68px);
    display: flex;
    flex-wrap: nowrap;
}
.notification__top-right {
    width: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification-title {
    width: fit-content !important;
    color: #1e2248;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    padding: 22px 32px
}
.notification-title:hover {
    cursor: pointer;
}
.notification-title__active {
    box-shadow: inset 0 -4px 0 0 #005eeb;
    color: #005eeb;
}

.notification-close {

}

.notification-close__btn path {
    fill: #afb9c3;
}
.notification-close__btn path:hover {
    fill: #8b929a;
    cursor: pointer;
}

.notification__middle {
    background: #f4f6f8;
    display: flex;
	padding: 8px 32px;
    min-height: 36px;
    max-height: 36px;
}

.notification__area {
    max-height: calc(100vh - 36px - 68px);
    overflow-y: scroll;
}

.notification-element {
    background: #edf4ff;
    border-radius: 8px;
    font-size: 16px;
    padding: 8px 16px;
    word-break: break-word;
    margin: 25px;
}