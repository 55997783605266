.setting-element {
    width: 100%;
    display: flex ;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 25px;
}

.setting-element select {
    border: 1px solid #dee2e6;
	border-radius: 6px;
	color: #1e2248;
	font-size: 13px;
    width: 422px;
	height: 31px;
	line-height: 26px;
	transition: .2s ease;
    margin-right: 5px;
    padding: 0 10px;
}

.setting-element label {
    font-size: 13px;
    color: #636f7f;
    width: 150px;
    flex-shrink: 0;
}

.setting-element textarea {
    height: 100px !important;
    vertical-align: top;
    text-align: start;
    border: 1px solid #dee2e6;
	border-radius: 6px;
	color: #1e2248;
	font-size: 13px;
    width: 400px;
	line-height: 26px;
	padding: 1px 10px;
	transition: .2s ease;
    resize: none;
}

.setting-element input {
    border: 1px solid #dee2e6;
	border-radius: 6px;
	color: #1e2248;
	font-size: 13px;
    width: 400px;
	height: 27px;
	line-height: 26px;
	padding: 1px 10px;
	transition: .2s ease;
    margin-right: 5px;
}

.setting-element-radio-group {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.setting-element-radio-group div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.setting-element-radio-group h1 {
    display: block;
    font-size: 13px;
    line-height: 18px;
	margin-bottom: 5px;
    font-style: normal;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Roboto,sans-serif;
    font-weight: 400;
    width: calc(100% - 20px);
}

.setting-element-radio-group h2 {
    display: block;
    color: #8a96a6;
	font-size: 11px;
	line-height: 16px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Roboto,sans-serif;
    font-weight: 400;
    margin-left: 20px;
}

.setting-element-radio {
    width: 15px !important;
    margin-right: 5px;
}

.setting-element input:focus {
    border: 1px solid #005eeb;
    outline: 3px solid #d1e5f9;
}

.setting-element input::placeholder {
    color: #b4b7b8;
    font-size: 13px;
}

.setting-element-boolean {
    width: 15px !important;
}

.setting-padding {
    padding: 5px 25px;
}