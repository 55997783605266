.button-save {
    background-image: linear-gradient(180deg,#22c993,#20a77f);
    outline: none;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Roboto,sans-serif;
	font-size: 14px;
	font-weight: 500;
	height: 25px;
	padding: 5px 20px;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 7px;
}